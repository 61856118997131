.loginContainer {
  margin-right: auto;
  margin-left: auto;
  padding-top: 100px;
  width: 315px;
}

.authContainer > * {
  margin: 1rem 0 1rem 0 !important;
}

.authContainer {
  display: flex;
  flex-direction: column;
}
